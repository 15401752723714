import * as React from "react"
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Div100vh from "react-div-100vh";
import Job from "../components/Job";
import localize from "../components/localize";
import MyPortableText from "../components/MyPortableText";

export const query = graphql`
query($slug: String){
sanityJobs(slug: {current: {eq: $slug}}) {
    title{
    _type
    en
    de
    }
    link
        _rawDescription
        _rawDescriptionlong
  }
  site {
    siteMetadata {
    lang{
        _type
        en
        de
        }
        angebot{
        _type
        en
        de
        }
        kunden{
        _type
        en
        de
        }
        impressum{
        _type
        en
        de
        }
        datenschutz{
        _type
        en
        de
        }
        agbs{
        _type
        en
        de
        }
        telefon{
        _type
        en
        de
        }
        startseite{
        _type
        en
        de
        }
        hierBewerben{
        _type
        en
        de
        }
    }
    }   
    allSanitySettings {
    edges {
      node {
      standorte {
          street
          plz
          _rawStadt
          _rawName
        }
        telefon
        skype
        email
        jobsexternal
        jobsexternallink
     }
     }
    }
    allSanityNetzwerk {
    edges {
      node {
      title{
      en
      de
      _type
      }
      }
      }
      }
}
    `;

const JobPage = ({data, location}) => {

    const page = data.sanityJobs;
    const settings = data.allSanitySettings.edges[0].node;
    const netzwerk = data.allSanityNetzwerk.edges[0].node;

    return (
      <div id={"top"}>
          <Layout location={location} metadata={data.site.siteMetadata} settings={settings} netzwerk={netzwerk.title}>
              <SEO title={"Jobs | Dr. Barbara Stampf"}/>
              <div className={"job__outer"}>
                  <div className="color-bg bg-blue left2"></div>
                  <div className="color-bg bg-orange right2"></div>
                  <div className="job__inner">
                      <h1 className={"uppercase color-light"}>{page.title}</h1>
                      <div className="description">
                          <MyPortableText value={page._rawDescription}></MyPortableText>
                      </div>
                      <MyPortableText value={page._rawDescriptionlong}></MyPortableText>
                      <a href={page.link} className="btn bg-orange-dark color-light uppercase hover-moveup">
                          {data.site.siteMetadata.hierBewerben}
                      </a>
                  </div>
              </div>
          </Layout>
      </div>
  )
}

export default localize(JobPage)
